import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StoreContext } from "../../../contexts";

import { useModal, useSelectR } from "../../../hooks";
import { ACTIONS } from "../../../store";
import { NAMES } from "../../../utilities/validation";
import { getURL } from "./components/Preview/utilities";

const countDownValue = 10;
const initialState = {
  end: null,
  paused: false,
  start: null,
  url: "",
  answers: {},
  currentAnswer: 0,
  currentQuestion: 1,
  totalQuestions: 0,
  child: 0,
  questionTimer: 0,
  questionEnd: false,
  showPartnerScreen: false,
  thankyouScreenTimer: 0,
  partnerScreenTimer: 0,
  showTimer: true,
  addChild: false,
  showQuestion: false,
  answerSubmitted: false,
  childrenError: false,
  optionsError: false,
  info: {
    projectType: "",
  },
};

// const videoInfo = {
//   name: "",
//   description: "",
//   date: "",
//   tags: {},
//   category: {}
// };

const useVideo = () => {
  const modal = useModal();
  const [state, setState] = useState(initialState);
  const [skipDedication, setSkipDedication] = useState(false);
  const { library, authorization, playlist } = useContext(StoreContext);
  const Ref = useRef(null);
  const { t } = useTranslation();

  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");

  const currentPlaylistId = playlist.getCurrentPlayingVideoId();

  const childOptions = [];
  authorization.persons.map((person) => {
    childOptions.push({
      label: `${person.first_name} ${person.last_name}`,
      value: person.id,
    });
  });

  childOptions.push({
    label: "Add new child",
    value: "add_new",
  });

  const children = useSelectR({
    label: "Select a child",
    options: childOptions,
    validators: [{ name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } }],
    value: "",
  });

  useEffect(() => {
    if (authorization.isAuthenticated) {
      authorization.getPersons({});
    }
  }, [authorization.isAuthenticated]);

  useEffect(() => {
    if (
      library[`success${ACTIONS.LIBRARY.SAVE_VIDEO_QUESTION_OPTION}`] &&
      !library[`isLoading${ACTIONS.LIBRARY.SAVE_VIDEO_QUESTION_OPTION}`]
    ) {
      if (state.currentQuestion < state.totalQuestions && state.currentAnswer) {
        setState((state) => ({
          ...state,
          currentQuestion: state.currentQuestion + 1,
          currentAnswer: 0,
          questionTimer: countDownValue,
        }));
      } else if (state.currentQuestion == state.totalQuestions) {
        setState((state) => ({
          ...state,
          showQuestion: true,
          answerSubmitted: true,
          // totalQuestions: 0,
          thankyouScreenTimer: countDownValue,
        }));

        const id = setInterval(() => {
          setState((state) => ({
            ...state,
            thankyouScreenTimer: state.thankyouScreenTimer - 1,
          }));
        }, 1000);
        Ref.current = id;
      }
    } else if (!library[`isLoading${ACTIONS.LIBRARY.SAVE_VIDEO_QUESTION_OPTION}`]) {
      if (Ref.current) clearInterval(Ref.current);

      const id = setInterval(() => {
        setState((state) => ({
          ...state,
          questionTimer: state.questionTimer - 1,
        }));
      }, 1000);
      Ref.current = id;
    }
  }, [library[`isLoading${ACTIONS.LIBRARY.SAVE_VIDEO_QUESTION_OPTION}`], library]);

  useEffect(() => {
    if (state.thankyouScreenTimer == 0) {
      playNextVideo();
      if (Ref.current) clearInterval(Ref.current);
    }
  }, [state.thankyouScreenTimer]);

  useEffect(() => {
    if (state.questionTimer == countDownValue) {
      if (Ref.current) clearInterval(Ref.current);

      const id = setInterval(() => {
        setState((state) => ({
          ...state,
          questionTimer: state.questionTimer - 1,
        }));
      }, 1000);
      Ref.current = id;
    }

    if (state.questionTimer == 0 && state.currentQuestion < state.totalQuestions) {
      setState((state) => ({
        ...state,
        questionTimer: countDownValue,
        currentQuestion: state.currentQuestion + 1,
      }));
    } else if (state.questionTimer == 0) {
      setState((state) => ({
        ...state,
        showQuestion: true,
        answerSubmitted: false,
        showPartnerScreen: true,
        // totalQuestions: 0,
      }));
      playNextVideo();
      if (Ref.current) clearInterval(Ref.current);
    }
  }, [state.questionTimer]);

  useEffect(() => {
    if (Ref.current && (state.currentAnswer || children.value.value)) {
      clearInterval(Ref.current);
      setState((state) => ({
        ...state,
        showTimer: false,
      }));
      if (children.value.value == "add_new") {
        showAddChildForm();
      }
    }
  }, [state.currentAnswer, children.value.value]);

  const showAddChildForm = () => {
    if (Ref.current) clearInterval(Ref.current);
    setState((state) => ({
      ...state,
      addChild: true,
      showTimer: false,
    }));
  };

  const backFromChildForm = () => {
    children.setValue({ label: "Select a Child", value: "" });
    console.log("BACK FROM CHILD FORM");
    setState((state) => ({
      ...state,
      showQuestion: true,
      addChild: false,
      showTimer: false,
    }));
  };

  const removeAddChildForm = () => {
    authorization.getPersons({});
    children.setValue({ label: "Select a Child", value: "" });

    setState((state) => ({
      ...state,
      showQuestion: true,
      childrenError: false,
      optionsError: false,
      addChild: false,
      showTimer: false,
      currentAnswer: 0,
      answers: {},
    }));
    library[`setError`](ACTIONS.LIBRARY.SAVE_VIDEO_QUESTION_OPTION, "");
  };

  const stopTimer = () => {
    if (Ref.current) {
      clearInterval(Ref.current);
    }
    setState((state) => ({
      ...state,
      showTimer: false,
    }));
  };

  const showQuestionScreen = (e) => {
    e.stopPropagation();
    if (Ref.current) {
      clearInterval(Ref.current);
    }
    children.setValue({ label: "Select a Child", value: "" });

    setState((state) => ({
      ...state,
      showQuestion: true,
      questionTimer: countDownValue,
      addChild: false,
      showTimer: true,
      answerSubmitted: false,
      currentAnswer: 0,
      answers: {},
    }));
    library.setError(ACTIONS.LIBRARY.SAVE_VIDEO_QUESTION_OPTION, false);
  };

  const playNextVideo = () => {
    let currentVideoIndex = -1;
    let nextVideoIndex = 0;

    if (!authorization.isUserMember && !library[`freeAccess`]) {
      // modal.onClose();
      setState((state) => ({
        ...state,
        showPartnerScreen: true,
        answerSubmitted: true,
      }));
      return;
    }

    playlist.items.map((playlistItem, index) => {
      if (playlistItem.video.id == currentPlaylistId) {
        currentVideoIndex = index;
      }
    });

    if (playlist.items.length) {
      if (currentVideoIndex < playlist.items.length - 1) {
        nextVideoIndex = currentVideoIndex + 1;
        const playlistItem = playlist.items[nextVideoIndex];

        playlist.setCurrentPlayingVideoId(playlistItem.video.id);

        updateVideo({
          end: playlistItem.video.end,
          start: playlistItem.video.start,
          url: getURL(playlistItem.video.url, {
            start: playlistItem.video.start,
          }),
          name: playlistItem.video.name,
          description: playlistItem.video.description,
          number: playlistItem.video.number,
          date: playlistItem.video.created_at,
          categories: playlistItem.video.video_directory.video_categories,
          questions: playlistItem.video.video_questions,
          tags: playlistItem.video.video_directory.video_tags,
          holiday: playlistItem.video.video_directory.holiday,
          file: playlistItem.video.video_directory.file,
          showQuestion: false,
          answerSubmitted: false,
          internal_id: playlistItem.video.video_directory.id,
          info: {
            projectType: ""
          }
        });
      } else {
        setState((state) => ({
          ...state,
          showQuestion: false,
          addChild: false,
          showTimer: false,
          answerSubmitted: false,
        }));
        playlist.toggle();
      }
    }
  };

  const isNotValid = !state.currentAnswer || !children.value.value;

  const setAnswer = (e, question) => {
    const answersData = state.answers;
    answersData[question] = e.target.value;
    setState((state) => ({
      ...state,
      answers: answersData,
      currentAnswer: e.target.value,
    }));
  };

  const setChild = (e) => {
    setState((state) => ({
      ...state,
      child: e.value,
    }));
  };

  const skipQuestion = () => {
    if (state.currentQuestion < state.totalQuestions) {
      setState((state) => ({
        ...state,
        currentQuestion: state.currentQuestion + 1,
        currentAnswer: 0,
      }));
    } else {
      setState((state) => ({
        ...state,
        showQuestion: false,
        // totalQuestions: 0,
      }));
      playNextVideo();
    }
  };

  const submitQuestion = () => {
    if (!isNotValid) {
      if (Ref.current) clearInterval(Ref.current);
      saveQuestionOption(state.currentAnswer);
      setState((state) => ({
        ...state,
        childrenError: false,
        optionsError: false,
      }));
    } else if (!children.value.value) {
      setState((state) => ({
        ...state,
        childrenError: true,
        optionsError: false,
      }));
    } else {
      setState((state) => ({
        ...state,
        childrenError: false,
        optionsError: true,
      }));
    }
  };

  const saveQuestionOption = (option_id) => {
    library[`${ACTIONS.LIBRARY.SAVE_VIDEO_QUESTION_OPTION}`]({
      option_id,
      person_id: children.value.value,
    });
  };

  const onPause = useCallback(() => {
    setState((state) => ({
      ...state,
      paused: true,
    }));
  }, []);

  const onSelect = useCallback(
    ({
      end = Infinity,
      start = 0,
      url,
      name,
      description,
      categories,
      questions,
      tags,
      file,
      number,
      date,
      showQuestion,
      answerSubmitted,
      internal_id,
      info,
    }) => {
      if (!skipDedication) {
        end = Infinity;
        start = 0;
      }
      date = mapDate(date);
      setState((state) => ({
        ...state,
        end: end,
        paused: false,
        start: start,
        url: url,
        name: name,
        description: description,
        categories: categories,
        questions: questions,
        tags: tags,
        number: number,
        date: date,
        file: file,
        totalQuestions: questions ? questions.length : 0,
        showQuestion,
        answerSubmitted,
        currentQuestion: 1,
        internal_id: internal_id,
        info: info,
      }));
    },
    [skipDedication]
  );

  const onUpdate = useCallback(
    ({ seconds }) => {
      if (state.end != null && seconds > state.end) onPause();
    },
    [state.end]
  );

  const onEnd = useCallback(() => {
    if (state.totalQuestions) {
      setState((state) => ({
        ...state,
        showQuestion: true,
        currentQuestion: 1,
        questionTimer: countDownValue,
        questionEnd: false,
        showTimer: true,
      }));
    } else {
      playNextVideo();
    }
    library.setError(ACTIONS.LIBRARY.SAVE_VIDEO_QUESTION_OPTION, false);
  });

  const onClose = useCallback(() => {
    setState((state) => ({
      ...state,
      paused: false,
      addChild: false,
      showQuestion: false,
      currentAnswer: 0,
      totalQuestions: 0,
      answers: {},
    }));

    modal.onClose();
  }, []);

  const onOpen = useCallback(
    ({ end, start, url, name, description, categories, questions, tags, file, number, date, internal_id, info }) => {
      onSelect({
        end,
        start,
        url,
        name,
        description,
        categories,
        questions,
        tags,
        file,
        number,
        date,
        internal_id,
        info,
      });
      let audioPlayer = document.querySelector(".music-player-audio");
      if (audioPlayer) {
        audioPlayer.pause();
      }
      modal.onOpen();
    },
    []
  );

  const updateVideo = useCallback(
    ({
      end,
      start,
      url,
      name,
      description,
      categories,
      questions,
      tags,
      file,
      number,
      date,
      showQuestion,
      internal_id,
      info,
    }) => {
      onSelect({
        end,
        start,
        url,
        name,
        description,
        categories,
        questions,
        tags,
        file,
        number,
        date,
        showQuestion,
        internal_id,
        info,
      });
    }
  );

  const onClickOverlay = useCallback((event) => {
    if (event.target.matches(`[data-name="video-overlay"]`)) onClose();
  }, []);

  const mapDate = (date) => {
    let dateObj = new Date(date);
    let monthName = dateObj.toLocaleString("en-us", { month: "short" });
    return `${monthName} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
  };

  return useMemo(() => {
    return {
      end: state.end,
      isOpened: modal.isOpened,
      paused: state.paused,
      start: state.start,
      url: state.url,
      name: state.name,
      description: state.description,
      categories: state.categories,
      questions: state.questions,
      tags: state.tags,
      file: state.file,
      number: state.number,
      showPartnerScreen: state.showPartnerScreen,
      date: state.date,
      internal_id: state.internal_id,
      info: state.info,
      showQuestion: state.showQuestion,
      answerSubmitted: state.answerSubmitted,
      currentAnswer: state.currentAnswer,
      currentQuestion: state.currentQuestion,
      totalQuestions: state.totalQuestions,
      answers: state.answers,
      questionTimer: state.questionTimer,
      questionEnd: state.questionEnd,
      thankyouScreenTimer: state.thankyouScreenTimer,
      partnerScreenTimer: state.partnerScreenTimer,
      showTimer: state.showTimer,
      addChild: state.addChild,
      childrenError: state.childrenError,
      optionsError: state.optionsError,
      onClickOverlay: onClickOverlay,
      onClose: onClose,
      onOpen: onOpen,
      onUpdate: onUpdate,
      updateVideo: updateVideo,
      onEnd: onEnd,
      skipDedication: skipDedication,
      setSkipDedication: setSkipDedication,
      setAnswer: setAnswer,
      setChild: setChild,
      skipQuestion: skipQuestion,
      submitQuestion: submitQuestion,
      showAddChildForm: showAddChildForm,
      removeAddChildForm: removeAddChildForm,
      backFromChildForm: backFromChildForm,
      showQuestionScreen: showQuestionScreen,
      stopTimer: stopTimer,
      children: children,
      isNotValid: isNotValid,
    };
  }, [
    modal.isOpened,
    state.end,
    state.paused,
    state.start,
    state.url,
    state.name,
    state.showQuestion,
    state.answerSubmitted,
    state.currentAnswer,
    state.showPartnerScreen,
    state.answers,
    state.questions,
    state.currentQuestion,
    state.totalQuestions,
    state.questionTimer,
    state.thankyouScreenTimer,
    state.partnerScreenTimer,
    state.showTimer,
    state.addChild,
    state.childrenError,
    state.optionsError,
    isNotValid,
    children,
    skipDedication,
  ]);
};

export default useVideo;
