import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

// import Loader from "../../atoms/Loader";
import { TYPES as LOGOTYPE_TYPES } from "../../atoms/Logotype";
import Modal from "../../organisms/Modal";
import thumbnail from "../../../assets/images/video-thumbnail-placeholder.png";
import Preview from "../../atoms/Preview";
import useVideo from "./useVideo";
import classes from "./VideoList.module.scss";
import PlaylistModal from "../../organisms/Playlist/PlaylistModal";
import { StoreContext } from "../../../contexts";
import { toJS } from "mobx";
import {
  Authorization,
  AuthorizationKids,
} from "../RestrictionModal/RestrictionModal";

const VideoList = observer(
  ({
    error,
    hasItems,
    isLoading,
    items,
    placeholder,
    onLoad,
    search,
    order,
    videoType,
    resetPage,
    setRequestPayload,
    holidayId,
    freeAccess,
    limitRecord,
    project,
    projectType,
  }) => {
    const { authorization, partnership } = useContext(StoreContext);
    console.log("Items:", toJS(items));

    const video = useVideo();

    const { t } = useTranslation();
    useEffect(() => {
      const timer = setTimeout(() => {
        resetPage();
        setRequestPayload({
          search: search,
          order: order,
          videoType: videoType,
          holidayId: holidayId,
          limitRecord: limitRecord,
        });
        onLoad();
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }, [search, order, videoType, holidayId, authorization.isAuthenticated]);

    useEffect(() => {
      partnership.getPartnership();
    }, [authorization.isAuthenticated]);

    return (
      <div className={classes.container}>
        <div className={classes.main}>
          {hasItems ? (
            <>
              <ul className={classes.list}>
                {items.map((item, index) => {
                  if (limitRecord == 5 && index != 0) {
                    return (
                      <li
                        data-find="1"
                        key={item.id + "_" + index}
                        className={classes.item}
                      >
                        <Preview
                          project={project}
                          projectType={projectType}
                          {...item}
                          placeholder={placeholder}
                          onSelect={video.onOpen}
                        />
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
              {!authorization.isAuthenticated && freeAccess ? (
                <AuthorizationKids
                  popup={video}
                  authorization={authorization}
                  partnership={partnership}
                />
              ) : (
                <>
                  {authorization.isUserMember || freeAccess ? (
                    <Modal opened={video.isOpened} style={video.style}>
                      <PlaylistModal video={video} />
                    </Modal>
                  ) : (
                    <Authorization
                      popup={video}
                      authorization={authorization}
                      partnership={partnership}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <div
              className={cs(classes.message, {
                [classes.empty]: !hasItems,
                [classes.error]: !!error,
              })}
            >
              {!isLoading &&
                (error ? t("generals.warnings.1") : t("pages.library.texts.5"))}
            </div>
          )}
        </div>
        {/* {hasNextPage && (
          <div className={classes.footer}>
            {isLoading ? (
              <Loader />
            ) : (
              <button className={classes.button} onClick={onLoad}>
                {t("pages.library.labels.1")}
              </button>
            )}
          </div>
        )} */}
      </div>
    );
  }
);

VideoList.propTypes = {
  error: PropTypes.bool,
  hasItems: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  logotype: PropTypes.oneOf(Object.values(LOGOTYPE_TYPES)),
  placeholder: PropTypes.string,
  onLoad: PropTypes.func.isRequired,
  holidayId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
  projectType: PropTypes.string.isRequired,
  freeAccess: PropTypes.bool,
  limitRecord: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  project: PropTypes.string,
};

VideoList.defaultProps = {
  error: false,
  hasItems: false,
  hasNextPage: false,
  isLoading: false,
  items: [],
  logotype: LOGOTYPE_TYPES.DEFAULT,
  placeholder: thumbnail,
  holidayId: 0,
  totalItems: 0,
  freeAccess: false,
  limitRecord: false,
  project: "",
};

export default React.memo(VideoList);
