import { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { APPLICATION_ROUTES, COOKIES, PROJECTS } from "../../../constants";
import { StoreContext } from "../../../contexts";
import { useQuery } from "../../../hooks";
import useForm from "./useForm";
import { getDots, getPackageView, getSteps } from "./utilities";
import { useEffect } from "react";
import Cookies from "../../../utilities/cookies";
import { animateScroll as scroll } from "react-scroll";

const useOrder = () => {
  const { checkout, authorization } = useContext(StoreContext);
  const { t } = useTranslation();
  const ADD_ANOTHER_CHILD = t("projects.maor-kids.add_another_child");

  const query = useQuery();
  const type = query.get("type") || PROJECTS.MY_MAOR.PACKAGES.DOUBLE_LAYER.NAME;
  // const action = query.get("action");

  const history = useHistory();
  const onNavigateToPackages = useCallback(() => {
    history.push(APPLICATION_ROUTES.MY_MAOR_PACKAGES);
  }, [history]);
  const onNavigateToPayment = useCallback(() => {
    history.push(APPLICATION_ROUTES.MY_MAOR_CHECKOUT);
  }, [history]);
  const onNavigateToSignIn = useCallback(() => {
    history.push(
      `${APPLICATION_ROUTES.SIGN_IN}?redirect=${encodeURIComponent(
        `${APPLICATION_ROUTES.MY_MAOR_ORDER}?type=${type}`
      )}`
    );
  }, [history, type]);

  const form = useForm();
  const defaultStep = 0;

  const [stepIndex, setStepIndex] = useState(0);

  const steps = getSteps(type, { form });
  const hasNextStep = stepIndex < steps.length - 1;
  const hasPreviousStep = stepIndex > 0;
  const step = steps[stepIndex];
  const dots = getDots(steps, stepIndex + 1).map((item) => ({
    ...item,
    title: t(item.title_translation_key),
  }));

  useEffect(() => {
    setStepIndex(defaultStep);
  }, [defaultStep]);

  let referredFrom = "";
  if (navigator.cookieEnabled) {
    referredFrom = localStorage.getItem("referral-code");
  }

  const onDecrementIndex = () => {
    setStepIndex((value) => value - 1);
  };
  const onIncrementIndex = () => {
    if (stepIndex == 0) {
      if (authorization.isAuthenticated) {
        if (form.password.valueOfOriginal) {
          checkout.setValue("reAuthenticated", true);
          authorization.signIn({
            email: form.email.valueOfOriginal,
            password: form.password.valueOfOriginal,
            onSuccess: incrementIndexValue,
          });
        } else {
          checkout.setValue("reAuthenticated", false);
          incrementIndexValue();
        }
      } else {
        authorization.signUp({
          ageStatus: "",
          email: form.email.valueOfConfirm,
          firstName: form.parentFirstName.value,
          lastName: form.parentLastName.value,
          password: form.password.valueOfConfirm,
          phone: "",
          referralCode: "",
          referredFrom: referredFrom,
          // onSuccess: incrementIndexValue,
        });
      }
      if (navigator.cookieEnabled) {
        localStorage.removeItem("referral-code");
      }
    } else {
      incrementIndexValue();
    }
    scroll.scrollToTop();
  };

  const incrementIndexValue = () => {
    setStepIndex((value) => value + 1);
  };

  const onSubmitAndNavigateToPackages = () => {
    Cookies.set({ name: COOKIES.ADD_ANOTHER_CHILD, value: 1 });
    form.onSubmit({ callback: onNavigateToPackages, persistCartId: true });
  };
  const onSubmitAndNavigateToPayment = () => {
    form.onSubmit({ callback: onNavigateToPayment });
  };

  console.log(step.isNotAddressScreen, "referredFrom");

  const onGoNext = hasNextStep
    ? onIncrementIndex
    : onSubmitAndNavigateToPayment;
  const onGoPrevious = () => {
    if (step.isNotChildScreen && authorization.persons.length != 0) {
      form.setAddChild(false);
    } else if (hasPreviousStep) {
      if (step.isNotAddressScreen && authorization.address.length != 0) {
        form.setAddAddress(false);
      } else {
        onDecrementIndex();
      }
    } else {
      onNavigateToPackages();
    }
  };

  return {
    ALTERNATIVE_SUBMIT_TITLE: ADD_ANOTHER_CHILD,
    backURL: APPLICATION_ROUTES.PARTNERS,
    dots: dots,
    isAuthenticated: authorization.isAuthenticated,
    hasNextStep: hasNextStep,
    step: step,
    type: type,
    view: getPackageView(type),
    onGoNext: onGoNext,
    onGoPrevious: onGoPrevious,
    onNavigateToSignIn: onNavigateToSignIn,
    onSubmitAndNavigateToPackages: onSubmitAndNavigateToPackages,
    stepIndex: stepIndex,
    page: "birthday",
  };
};

export default useOrder;
